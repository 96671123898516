import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';
import { Component } from '@angular/core';
import { StaticFields } from './staticFields';

@Component({
    template: `
        <div style="margin: 8px">
            <span>
                <label>
                    Set Color :  
                </label>
                <p-colorPicker appendTo="body"  [(ngModel)]="selectedColor" (onChange)="changeColor($event)"></p-colorPicker>
            </span>  
        </div>
    `,
    styles :[' :host ::ng-deep .p-colorpicker-preview { cursor: pointer; width: 22px; height: 22px;} ' ]
})
export class SetCustomColorComponent implements IStatusPanelAngularComp {
    private params!: IStatusPanelParams;
    public selectedColor: string| undefined = "#FFFFFF";

    agInit(params: IStatusPanelParams): void {
        this.params = params;
        if(StaticFields.precodeScreenData['selectedColor']) {
            this.selectedColor = StaticFields.precodeScreenData['selectedColor'];
        }
    }


    changeColor(event : any) {
        StaticFields.precodeScreenData['selectedColor'] = event.value;
        this.params.context.updateColor();
    }

}