
<p-panel [toggleable]="true" header="Precode Preferences" >
    <ng-template pTemplate="icons">
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]=" !allowReset"  title="Save Preferences" (onClick)="savePreferences()"> Save</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Cancel Changes" [disabled]="!allowReset" (onClick)="resetPreferences()"> Reset</p-button>
    </ng-template>
    
    <div class="flex flex-row flex-wrap ml-2 mt-0.5  gap-6" style="padding-top: 15px;">
        <div>
            <label for="crop">Crop: </label>
            <p-dropdown [options]="crops" [(ngModel)]="selectedCrop" (onChange)="changeCrop($event)" [filter]="true" optionLabel="name" placeholder="Select" />
        </div>
        <div>
            <label for="level">Level: </label>
            <p-dropdown [options]="levels" [(ngModel)]="selectedLevel" (onChange)="changeLevel($event)" optionLabel="name" placeholder="Select" />
        </div>
        <div>
            <label for="include">Include:</label>
            <p-multiSelect [options]="inclusions" [(ngModel)]="selectedInclusions" placeholder="Select" />
        </div>
        <div>
            <label for="stage">Stage:</label>
            <p-multiSelect [options]="stages" [(ngModel)]="selectedStages" placeholder="Select" />
        </div>
    </div>
    <div style="margin: 10px;">
        <label style="text-transform: capitalize;font-weight: 800;">Expand By :</label>
    </div>
    <div class="flex flex-row flex-wrap ml-2 pb-4 gap-6">
        <div>            
            <p-checkbox [(ngModel)]="geLineageChecked" label="GE Lineage" [binary]="true" inputId="binary" />
        </div>
        <div>
            <p-checkbox [(ngModel)]="seedInventoryChecked" label="Seed Inventory" [binary]="true" inputId="binary" />
        </div>
        <div><p-checkbox [(ngModel)]="popRequestChecked" label="Pop Request" [binary]="true" inputId="binary" />
        </div>
        <div>
            <p-checkbox [(ngModel)]="experimentsChecked" label="Experiments" [binary]="true" inputId="binary" />
        </div>
    </div>
</p-panel>
<p-confirmDialog [style]="{'width' : '450px', 'height' : '230px'}">   
</p-confirmDialog> 
