import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { ApiService } from './services/api.service';
import { StaticFields } from './shared/staticFields';
import * as FileSaver from 'file-saver';
import { UserClaim } from './models/userclaim';
import { Settings } from './models/settings';
import { SettingService } from './services/setting.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { NgxCaptureService } from 'ngx-capture';
import { CustomMessageService } from './services/custom.message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public versionNumber = environment.appVersion;
  public environment = "";
  title = 'PRECODEMANAGER_UI';
  imageSrc: string = "";
  isIframe: boolean = false;
  isNavVisible: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay: boolean = false;
  userClaim: any = null;
  errors: any[] = JSON.parse(localStorage.getItem("errors") || "[]");
  roles: string[] | undefined;
  isPCMAdmin: boolean = false;
  isPCMUser: boolean = false;
  translationForGrid: any[] = [];
  showMapModal: boolean = false;
  selectedMapHeaders : any[] = []

  constructor(
    @Inject(SettingService) private config: Settings,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private apiService : ApiService,
    public customMessageService: CustomMessageService,
    private msalBroadcastService: MsalBroadcastService,
    public dialogService : DialogService,
    private router: Router, 
    private captureService : NgxCaptureService,
    @Inject(DOCUMENT) private document: Document
  ) {      
    if (this.config.environment.toUpperCase() === 'LOCAL' || this.config.environment.toUpperCase() === 'DEV' || this.config.environment.toUpperCase() === 'QA' || this.config.environment.toUpperCase() === 'TR') {
      this.environment = "(" + this.config.environment + ")";
    }
    this.customMessageService.errorMsgs$.subscribe(result => {
      if (result.severity === 'error') {
        setTimeout(()=> {
          this.captureService.getImage(document.body, true).subscribe((img: any)=>{
            result['img'] = img;
            let collection = [];
            let submitData : any = {};
            // submitData['guid'] = this.getGUID();
            submitData['errorDateTime'] = new Date(result.dateTime).toUTCString();
            submitData['errorMessage'] = result.detail;
            submitData['screenShot'] = result.img;
            submitData['actionById'] = StaticFields.appUserId;
            collection.push(submitData);
            this.apiService.putData("SubmitPCMErrorScreenShots",collection, false).subscribe();
            this.appendError(result);
          })
        },1000);
      } else
        this.appendError(result);
    });
   
  }

  get allowSave(): boolean {
    if (StaticFields.homeScreenData.mappingModel) {
      let data : any = {};
      this.translationForGrid.forEach((item : any) => {
        data[item.headerName] = item.data;
      });
      return StaticFields.homeScreenData.mappingModel !== JSON.stringify(data);
    }
    return false;
  }


  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe(async (result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        // await this.msalService.instance.handleRedirectPromise();
        await this.msalService.instance.loginRedirect();
        window.location.pathname = "/";
      } else {
        this.setLoginDisplay();
      }
    });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
     
    if (this.msalService.instance.getActiveAccount()) {
      this.getRoles();
      if(this.roles!.length > 0 )
        this.isNavVisible = true;     
      this.setOnLoad();
    } else {
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.isNavVisible = false;
        } else {
            this.getRoles();
            if(this.roles!.length > 0 )
               this.isNavVisible = true;  ;   
        }
        if (this.isNavVisible) { 
          this.setOnLoad();        
        }
      });
    }    
    
  }

  ngAfterViewInit(): void {
    const elements = document.getElementsByClassName("footer-container");
    while(elements.length > 0){
        elements[0]?.parentNode.removeChild(elements[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  private setOnLoad() {
    if (StaticFields.userInfo === undefined || StaticFields.userInfo === null) {
        this.apiService.getUserInfo().subscribe((result: any) => {
          StaticFields.userInfo = result;
          this.setInitialData();
        });  
    } else {
      this.setInitialData();
    }
  }

  setInitialData() {
    if (StaticFields.homeScreenData === undefined || StaticFields.homeScreenData === null) {
      let params = new HttpParams();
      params = params.append('userName', StaticFields.userInfo.onPremisesSamAccountName);
      this.apiService.getData('GetHomePageData', params).subscribe((result : any) => {
        if (result.success) {
          StaticFields.appUserId = result.appUserId;
          StaticFields.homeScreenData = result.data ? result.data : {
            "actionBy" : "",
            "actionById" : "",
            "actionOn" : "",
            "id" : "",
            "box1" : "",
            "box2" : "",
            "box3" : "",
            "box4" : "",
            "mappingModel" : ""
          };
        }
      });
    }    
  }
  
  appendError(result: any) {
    let errors = JSON.parse(localStorage.getItem("errors") || "[]");
    if (errors.length === 10) {
      errors.pop();
      errors.unshift(result);
    } else {
      errors.unshift(result);
    }
    this.errors = errors;
    localStorage.setItem("errors", JSON.stringify(errors));
  }
  
  clearErrors() {
    this.errors = [];
    localStorage.setItem("errors", JSON.stringify(this.errors));
  }

  showMappingModal() {
    if (StaticFields.homeScreenData && StaticFields.homeScreenData.mappingModel)
      this.fillModelData(StaticFields.homeScreenData.mappingModel);
    this.showMapModal = true;
  }

  onAddNewRow(){
    this.translationForGrid.unshift({});
  }

  onRemoveRow() {
    this.translationForGrid = this.translationForGrid.filter(ele => {return  !this.selectedMapHeaders.find(val =>  {return val.headerName == ele.headerName})});
    this.selectedMapHeaders.splice(0);
  }

  fillModelData(data : any) {
    this.translationForGrid.splice(0);
    if (data) {
      const translationData = JSON.parse(data);
      Object.keys(translationData).forEach(ele => {
        this.translationForGrid.push({'headerName': ele, 'data': translationData[ele] });
      });
    }
  }

  SubmitHomePageMappingModel() {
    let data : any = {};
    this.translationForGrid.forEach((item : any) => {
      data[item.headerName] = item.data;
    })
    StaticFields.homeScreenData.mappingModel = JSON.stringify(data);
    let submitData : any = StaticFields.homeScreenData;
    this.apiService.putData('SubmitHomePageData',JSON.stringify(submitData)).subscribe((result : any)  => {
      if (result && result.success) {
        StaticFields.homeScreenData = result.data;
        this.fillModelData(StaticFields.homeScreenData.mappingModel);
        this.showMapModal = false;
      }      
    });
  }

  // downloadImage(data : any) {
  //   const blob = this.DataURIToBlob(data.img);
  //   FileSaver.saveAs(blob, "screenshot.png");
  // }

  bgColor(rowData : any) : any {
    if(rowData.severity === "success") {
      return "#b7d8b7";
    }else if(rowData.severity === "error") {
      return "#f8b7bd";
    }else if(rowData.severity === "warn") {
      return "#ffe399";
    }else if(rowData.severity === "info") {
      return "#7fbcec";
    }
  }

  color(rowData : any) : any {
    if(rowData.severity === "success") {
      return "#000000";
    }else if(rowData.severity === "error") {
      return "#000000";
    }else if(rowData.severity === "warn") {
      return "#000000";
    }else if(rowData.severity === "info") {
      return "#000000";
    }
  }

  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',');
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        
  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i)
      
  //   return new Blob([ia], { type: mimeString })
  // }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest).subscribe({
        next: (res) => {
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.msalService.loginRedirect();
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      
    } 
    if (activeAccount) {       
      this.userClaim = new UserClaim();
      const url = this.document.location.toString();
      const pageName = url.substring(url.lastIndexOf("/"), url.length);
      this.getRoles();
      
    } else {

    }
  }

  public getRoles() {
    let allAccounts = this.msalService.instance.getAllAccounts();
      if (allAccounts.length > 0) {
        let account = allAccounts[0];
        this.roles = account.idTokenClaims!.roles;
        this.isPCMAdmin = this.roles!.find(ele => ele == "PCM.Admin") ? true : false;
        this.isPCMUser = this.roles!.find(ele => ele == "PCM.User") ? true : false;
      } else {
        this.router.navigate(['error']);
      }
  }

  downloadImage(data : any) {
    const blob = this.DataURIToBlob(data.img);
    FileSaver.saveAs(blob, "screenshot.png");
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
      
    return new Blob([ia], { type: mimeString })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
