import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { GridPreference, PreCodePreferences } from 'src/app/shared/commonModels';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent {

  stageDict: any = {};
  crops: any[];
  selectedCrop: any;
  levels: any[] = [{name: 'Program(s)'},{name: 'Region(s)'},{name: 'EZ(s)'}];
  selectedLevel: any;
  inclusions: any[];
  selectedInclusions: any[] = [];
  stages: string[] = [];
  selectedStages: any[] = [];
  geLineageChecked: boolean = false;
  seedInventoryChecked: boolean = false;
  popRequestChecked: boolean = false;
  experimentsChecked: boolean = false;
  regions: any[] = [];
  ezs: any = [];
  programs: any = [];
  precodePreference : PreCodePreferences = new PreCodePreferences();
  settingPageLoadData: boolean = false;

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService) { }

  get allowReset() : boolean {
    if(this.precodePreference.actionById && this.selectedCrop && this.selectedLevel){
      return this.selectedCrop.name !== this.precodePreference.crop || this.selectedLevel.name !== this.precodePreference.level || 
      ((this.precodePreference.include !== "" || this.selectedInclusions.join(",") !== "") && JSON.stringify(this.selectedInclusions.sort((a,b) => a-b)) !== JSON.stringify(this.precodePreference.include.split(",").sort((a: any,b: any) => a-b))) ||
      ((this.precodePreference.stage !== "" || this.selectedStages.join(",") !== "" ) && JSON.stringify(this.selectedStages.sort((a,b) => a-b)) !== JSON.stringify(this.precodePreference.stage.split(",").sort((a: any,b: any) => a-b)))  ||
      this.geLineageChecked !== this.precodePreference.geLineage || this.popRequestChecked !== this.precodePreference.popRequest ||
      this.seedInventoryChecked !== this.precodePreference.seedInventory || this.experimentsChecked !== this.precodePreference.experiments;  
    } else {
      return this.selectedCrop || this.selectedLevel || this.selectedInclusions  || this.selectedInclusions.length > 0
    }
  }

  ngOnInit(): void {
    this.apiService.getData('GetCrops').subscribe((result : any) => {
      if (result.success) {
        this.crops = result.data;
        if (StaticFields.appUserId)
          this.getPreferences();  
        else {
          const interval = setInterval(() => {
            if (StaticFields.appUserId) {
              this.getPreferences();  
              clearInterval(interval);
            }
          }, 2000);
        }       
      }
    });   
  }


  getPreferences () {
    let params = new HttpParams();
    params = params.append('type', 'PreCode');
    params = params.append('actionById', StaticFields.appUserId);
    this.apiService.getData('GetPreferences', params, false).subscribe((res: any) => {
      if (res.success) {
        this.precodePreference = res.data ? res.data : new GridPreference();
        this.setScreenData();
      }      
    });
  }

  setScreenData() {
    if(this.precodePreference.actionById) {
      this.selectedCrop = this.crops.find(ele => ele.name == this.precodePreference.crop);
      this.changeCrop();        
      this.selectedLevel = this.levels.find(ele => ele.name == this.precodePreference.level);
      this.changeLevel();
      this.geLineageChecked = this.precodePreference.geLineage;
      this.popRequestChecked = this.precodePreference.popRequest;
      this.seedInventoryChecked = this.precodePreference.seedInventory;
      this.experimentsChecked = this.precodePreference.experiments;

    }
  }

  changeCrop(event? : any) {
    this.getMasterData();
  }

  changeLevel(event? : any) {
    if (this.selectedLevel.name === "Program(s)") {
      this.inclusions = this.programs.map((d: any) => d.code);
    } else if (this.selectedLevel.name === "Region(s)") {
      this.inclusions = this.regions.map((d: any) => d.code);
    } else if (this.selectedLevel.name === "EZ(s)") {
      this.inclusions = this.ezs.map((d: any) => d.code);
    } else if (this.inclusions){
      this.inclusions.splice(0);
    }

    if (this.precodePreference.actionById && this.inclusions.length > 0) {
      const inclPredicate = this.precodePreference.include.split(",").some(ele => this.inclusions.includes(ele));
      const stagePredicate = this.precodePreference.stage.split(",").some(ele => this.stages.includes(ele));
      this.selectedInclusions =  inclPredicate && this.precodePreference.include!= "" &&  this.precodePreference.include.split(",").length > 0 ?  this.precodePreference.include.split(",") : [] ;
      this.selectedStages = stagePredicate && this.precodePreference.stage!= ""  && this.precodePreference.stage.split(",").length > 0 ? this.precodePreference.stage.split(",") : [] ;
    }
  }

  getRegionsPrograms() {
    let params = new HttpParams();
    params = params.append('cropId', this.selectedCrop.id);
    this.apiService.getData('GetRegions', params, false).subscribe((result : any) => {
      if (result.success) {
        this.regions = result.data;
        if (this.selectedLevel && this.selectedLevel.name === "Region(s)")
          this.changeLevel();
        } 
    });
    this.apiService.getData('GetEvaluationZones', params, false).subscribe((result : any) => {
      if (result.success) {
        this.ezs = result.data;
        if (this.selectedLevel && this.selectedLevel.name === "EZ(s)")
          this.changeLevel();
        } 
    });
    this.apiService.getData('GetPrograms', params, false).subscribe((result : any) => {
      if (result.success) {
        this.programs = result.data;
        if (this.selectedLevel && this.selectedLevel.name === "Program(s)")
          this.changeLevel();
        } 
    });
  }

  getMasterData() {
    this.stageDict = {};
    this.stages.splice(0);
    let params = new HttpParams();
    params = params.append('type', 'stage');
    params = params.append('crop', this.selectedCrop.name);
    params = params.append('activeOnly', true);
    this.apiService.getData('GetMasterData', params).subscribe((result : any) => {
      if (result.success) {
        result.data.forEach((s: any) => {
          if (!(s.stage in this.stageDict)) {
            this.stageDict[s.stage.toUpperCase()] = [];
            this.stageDict[s.stage.toUpperCase()].push(s.stage);
            this.stages.push(s.stage);
          }
          if (s.nextStage) {
            this.stageDict[s.stage.toUpperCase()].push(s.nextStage);
            const foundNextStage = result.data.find((d: any) => d.stage.toUpperCase() === s.nextStage.toUpperCase())
            if (foundNextStage && foundNextStage.nextStage)
              this.stageDict[s.stage.toUpperCase()].push(foundNextStage.nextStage);
          }
        });       
      } 
      this.getRegionsPrograms();  
    });
  }

  
  getExpandOptions() {
    const expandBy: string[] = [];
    if (this.geLineageChecked)
      expandBy.push('GE');
    if (this.seedInventoryChecked)
      expandBy.push('SI');
    if (this.popRequestChecked)
      expandBy.push('POP');
    if (this.experimentsChecked)
      expandBy.push('EXPT');
    return expandBy.join();
  }



  resetPreferences() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to reset the screen?`,     
      accept: () => {
        if(this.precodePreference.actionById) {
          this.setScreenData();
        } else {
        this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = null;
        this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = false;
        }
       
      }
    });
  }

  savePreferences() {
    if (!this.precodePreference.actionById) {
      this.precodePreference.actionById = StaticFields.appUserId;
    }
    this.precodePreference.type = 'PreCode';
    this.precodePreference.crop = this.selectedCrop.name;
    this.precodePreference.level = this.selectedLevel.name;
    this.precodePreference.include = this.selectedInclusions.join();
    this.precodePreference.stage =  this.selectedStages ? this.selectedStages.join() : '';
    this.precodePreference.geLineage = this.geLineageChecked;
    this.precodePreference.popRequest = this.popRequestChecked;
    this.precodePreference.seedInventory = this.seedInventoryChecked;
    this.precodePreference.experiments = this.experimentsChecked;
    
    this.apiService.putData('SavePreferences',this.precodePreference ).subscribe();
      // if (result.success) {
      //   this.customMessageService.showMessage({'severity' : 'success', 'summary': 'Changes Saved', 'detail' : result.message});
      // } else {
      //   this.customMessageService.showMessage({'severity' : 'error', 'summary': 'Failed', 'detail' : result.message});
      // }
   // });
  }
}
