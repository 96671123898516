import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '../../services/api.service';
import { CustomMessageService } from '../../services/custom.message.service';
import { ColDef, GridReadyEvent, StatusPanelDef } from 'ag-grid-community';
import { ColorPickerModule } from 'primeng/colorpicker';

@Component({
  selector: 'app-gridPerf',
  templateUrl: './gridPerf.component.html',
  styleUrls: ['./gridPerf.component.scss']
})
export class GridPerfComponent implements OnInit{
  selColor: any = '#ffffff';
  showGrid = true;
  gridApi: any;
  isEditable = false;
  rowData : any[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 100,
    editable: false
  };
  statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
      { statusPanel: 'agAggregationComponent' }
    ],
  };
  rowStyle = (params: any) : any => {
    if (params.data.color)
      return { backgroundColor: params.data.color };
    return null;
  };
  public columnDefs: ColDef[] = [];
  
  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService) { }
  
  ngOnInit(): void {
    const editValues = ["BMW","Hyundai","TATA","Mahindra","Skoda","Dodge","Porsche", "Toyota", "Ford", "AAA", "BBB", "CCC"];
    for (var j = 0; j < 75; j++) {
      const editorType = j % 2 ? "agTextCellEditor" : "agSelectCellEditor";
      this.columnDefs.push({
        'headerName': "Column " + j, 'field': "Column " + j, headerTooltip: "Column " + j, width: 150,
        minWidth: 130, filter: 'agSetColumnFilter', cellEditor: editorType,
        cellEditorParams: {
          values: editValues,
        },
        cellStyle: params => {
          const cellId = `${params.column.getColId()}_cellColor`;
          if (params.data[cellId])
            return { backgroundColor: params.data[cellId]};
          return null;
        }
      });
    }
    let counter = 100;
    let str = ' This is a long string. Lets see the grid performance after loading string values.';
    for (var i = 0; i < 160000; i++) {
      const obj : any = {};
      for (var j = 0; j < 75; j++) {
        const col = `Column ${j}`;
        const val = j % 2 ? counter + j : (i + j) + str;
        obj[col] = val;
      }
      this.rowData.push(obj);
      counter = counter + 50;
    }
  }
  get getSelectedRows(): any {
    return this.gridApi ? this.gridApi.getSelectedRows() : null;
  }
  setGridEditable() {
    this.showGrid = false;
    this.isEditable = !this.isEditable;
    this.defaultColDef.editable = this.isEditable;
    setTimeout(() => {
      this.showGrid = true;
    }, 1);
  }
  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }
  getContextMenuItems(params: any) {
    var result = [
      {
        name: 'Fill Up',
        disabled: !(params.value && params.node.rowIndex > 0),
        action: function () { params.context.fill(params, "up"); },
      },
      {
        name: 'Fill Down',
        disabled: !(params.value && params.node.rowIndex < params.context.rowData.length),
        action: function () { params.context.fill(params, "down"); }
      },
      {
        name: 'Fill Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 1),
        action: function () { params.context.fill(params, "selected"); }
      },
      'copy',
      'copyWithHeaders',
      'separator',
      'export'
    ]
    return result;
  }

  fill(params: any, fillType: string) {
    if (fillType === "down" || fillType === "up") {
      params.api.forEachNodeAfterFilterAndSort((rowNode: { data: any; }, index: number) => {
        if (fillType === "up" ? index < params.node.rowIndex : index > params.node.rowIndex) {
          rowNode.data[params.column.colId] = params.value;
          index++;
        }
      });
    } else if (fillType === "selected") {
      this.getSelectedRows().forEach((rowNode: any) => {
        rowNode.data[params.column.colId] = params.value;
      });
    }
    params.api.refreshCells(params);
  }
  setColor(isRow: boolean) {
    const selCells = this.gridApi.getCellRanges();
    const selRows : any[] = [];
    selCells.forEach((r : any) => {
      const startIdx = r.startRow.rowIndex;
      let endIdx = r.endRow.rowIndex;
      let idx = startIdx;
      if (endIdx < startIdx) {
        idx = endIdx;
        endIdx = startIdx;
      }
      while (idx <= endIdx) {
        var row = this.gridApi.getDisplayedRowAtIndex(idx);
        if (isRow)
          row.data.color = this.selColor;
        else {
          r.columns.forEach((c : any) => {
            row.data[`${c.colId}_cellColor`] = this.selColor;
          });
        }
        selRows.push(row);
        idx++
      }
    });
    this.gridApi.redrawRows({ rowNodes: selRows });
  }
}