<div style="display: flex;justify-content: flex-end;"*ngIf="isPCMAdmin">
    <div >
        <p-button *ngIf="hideEditorCtrl" label="Cancel" (onClick)="cancelEdit()" data-toggle="tooltip" data-placement="top" title="Cancel Changes" [style]="{'margin-right':'5px'}"></p-button>
        <p-button *ngIf="hideEditorCtrl"  label="Save" (onClick)="saveHomePageData()" data-toggle="tooltip" data-placement="top" title="Save Changes"></p-button>
        <p-button *ngIf="!hideEditorCtrl"  label="Edit" (onClick)="editContent()" data-toggle="tooltip" data-placement="top" title="Edit HomeScreen"></p-button>
    </div>
</div>

<p-confirmDialog [style]="{'width' : '400px', 'height' : '200px'}">
</p-confirmDialog>


<div class="row" *ngIf="!hideEditorCtrl">
    <div class="col-6 mar-top">
        <p-editor [(ngModel)]="homeScreenData.box1" [readonly]="true" [style]="{'height':'320px'}">
            <ng-template pTemplate="header">
                <span class="ql-format-group">
                    <span title="Bold" class="ql-format-button ql-bold"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Italic" class="ql-format-button ql-italic"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Underline" class="ql-format-button ql-underline"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                </span>
            </ng-template>
        </p-editor>
    </div>
    <div class="col-6 mar-top">
        <p-editor [(ngModel)]="homeScreenData.box2" [readonly]="true" [style]="{'height':'320px'}">
            <ng-template pTemplate="header">
                <span class="ql-format-group">
                    <span title="Bold" class="ql-format-button ql-bold"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Italic" class="ql-format-button ql-italic"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Underline" class="ql-format-button ql-underline"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                </span>
            </ng-template>
        </p-editor>
    </div>
    <div class="col-6 mar-top mar-bot">
        <p-editor [(ngModel)]="homeScreenData.box3" [readonly]="true"  [style]="{'height':'320px'}">
            <ng-template pTemplate="header">
                <span class="ql-format-group">
                    <span title="Bold" class="ql-format-button ql-bold"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Italic" class="ql-format-button ql-italic"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Underline" class="ql-format-button ql-underline"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                </span>
            </ng-template>
        </p-editor>
    </div>
    <div class="col-6 mar-top mar-bot">
        <p-editor [(ngModel)]="homeScreenData.box4" [readonly]="true" [style]="{'height':'320px'}">
            <ng-template pTemplate="header">
                <span class="ql-format-group">
                    <span title="Bold" class="ql-format-button ql-bold"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Italic" class="ql-format-button ql-italic"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Underline" class="ql-format-button ql-underline"></span>
                    <span class="ql-format-separator"></span>
                    <span title="Strikethrough" class="ql-format-button ql-strike"></span>
                </span>
            </ng-template>
        </p-editor>
    </div>
</div>
<div class="row" *ngIf="hideEditorCtrl">
    <div class="col-6 mar-top">
        <p-editor [(ngModel)]="homeScreenData.box1" [style]="{'height':'320px'}">
        </p-editor>
    </div>
    <div class="col-6 mar-top">
        <p-editor [(ngModel)]="homeScreenData.box2" [style]="{'height':'320px'}">
        </p-editor>
    </div>
    <div class="col-6 mar-top mar-bot">
        <p-editor [(ngModel)]="homeScreenData.box3" [style]="{'height':'320px'}">
        </p-editor>
    </div>
    <div class="col-6 mar-top mar-bot">
        <p-editor [(ngModel)]="homeScreenData.box4" [style]="{'height':'320px'}">
        </p-editor>
    </div>
</div>



