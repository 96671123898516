
<div class="flex">
  <label style="width: 112px; font-weight: normal;margin-top: 3px;margin-right: 3px;">Select Color</label>
  <div style="max-width: 10px; margin-top: 1px;margin-left: -20px;">
    <p-colorPicker #pcp [(ngModel)]="selColor"></p-colorPicker>
  </div>
  <button pButton type="button" label="Set Row Color" [disabled]="getSelectedRows?.length === 0" (click)="setColor(true)" style="margin-left: 30px; width: 120px; padding: 3px"></button>
  <button pButton type="button" label="Set Cell Color" [disabled]="getSelectedRows?.length === 0" (click)="setColor(false)" style="margin-left: 10px; width: 120px; padding: 3px"></button>
  <button pButton type="button" [label]="isEditable ? 'Set ReadOnly' : 'Make Editable'" (click)="setGridEditable()" style="margin-left: 10px; width: 120px; padding: 3px"></button>
  <label style="margin-left: 30px; color: gray; font-size: small; width: 500px; padding: 4px;">{{isEditable ? '*Double click on row to Edit. Right click for fill options.' : '*Right click for fill options.'}}</label>
</div>
<ag-grid-angular *ngIf="showGrid" id="agGrid" (gridReady)="onGridReady($event)" [getContextMenuItems]="getContextMenuItems"
  style="height: 75vh; width: 100%; margin-left :2px; margin-right: 5px;margin-top: 3px;margin-bottom: 3px;"
  class="ag-theme-balham" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [animateRows]="true" [rowData]="rowData" 
  [enableRangeSelection]=true [statusBar]="statusBar" [sideBar]="{ toolPanels: ['columns', 'filters'] }"
  [rowSelection]="'multiple'" [animateRows]="true" [context]="this" [getRowStyle]="rowStyle"></ag-grid-angular>