<span #screen>
	<p-toast [style]="{marginTop: '80px','position': 'fixed'}" [autoZIndex]="true" [baseZIndex]="999999"></p-toast>
	<res-layout>
		<res-header>
      <res-logo appName="PRECODE MANAGER {{environment}}" [showAppName]="true">
        <img res-logo src="../favicon.ico" width="50" height="50"/>
      </res-logo>
		</res-header>
		<res-navigation [hidden]="!isNavVisible" style="margin-bottom: 5px;">
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top" title="Contains basic instructions to use PRECODEMANAGER" href="#"  routerLink="/home">Home</a>
			</res-navigation-item>
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Preferences" href="*" routerLink="/preferences">Preferences</a>
			</res-navigation-item>
			<res-navigation-item>
				<a [routerLinkActive]="'active'" class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Precode" href="*" routerLink="/manageprecode">Manage Precode</a>
			</res-navigation-item>
			<res-navigation-item>
				<a  class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Precode" href="*" routerLink="/underconstruction"> Code</a>
			</res-navigation-item>
			<res-navigation-item>
				<a  class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Precode" href="*" routerLink="/underconstruction"> LineGraph</a>
			</res-navigation-item>
			<res-navigation-item>
				<a  class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Precode" href="*" routerLink="/underconstruction"> GET Apex Results</a>
			</res-navigation-item>
			<res-navigation-item>
				<a  class="resNavItem" data-toggle="tooltip" data-placement="top" title="Manage Precode" href="*" routerLink="/underconstruction"> Import Precodes</a>
			</res-navigation-item>
			<res-navigation-group *ngIf="isPCMAdmin" name="Admin">
				<res-navigation-item resNavItem  [routerLinkActive]="'active'" (click)="showMappingModal()" class="groupItem" data-toggle="tooltip" data-placement="top" title="Manage file import translation headers">File Header Model</res-navigation-item>
				<res-navigation-item resNavItem  [routerLinkActive]="'active'" routerLink="/admin" class="groupItem" data-toggle="tooltip" data-placement="top" title="Manage file import translation headers">Manage PreCode/MetaData</res-navigation-item>
			</res-navigation-group>
			<i (mouseenter)="op2.show($event)" class="pi pi-info-circle" style="font-size: 19px; color: #0072CE;"></i>
		</res-navigation>
		<router-outlet></router-outlet>
	</res-layout>
	<app-loading [imageSrc]="imageSrc" [debounceTime]="300"></app-loading>
	<p-overlayPanel #op2 [showCloseIcon]="true" [dismissable]="true"  appendTo="body">
		<ng-template pTemplate="header">
			<div class="grid" style="height: 30px,">
				<div class="col-8">
					<label style="font-weight: bold; font-size: large; margin-left: 15px;">App Messages</label>
				</div>
				<div style="margin-top: 10px;">
					<label style="font-size: 14px;">{{environment}} Version: {{versionNumber}}</label>
				</div>
			</div>			
        </ng-template>
		<ng-template pTemplate="content">
			<p-table [value]="errors" [style]="{width: '600px', padding: '10px'}"  >
				<ng-template pTemplate="caption">
					<div style="text-align:left">
						<button pButton type="button" (click)="clearErrors()" style="width: 50px;height: 25px; padding: 3px;" class="ui-button-info">Clear</button>
					</div>
				</ng-template>
				<ng-template pTemplate="header" >
					<tr>
						<th style="padding-left: 2px; width: 60px;">Summary</th>
						<th style="padding-left: 2px;">Details</th>
						<th style="padding-left: 2px; width: 60px;">Time</th>
						<th style="padding-left: 5px; width: 60px;">Image</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
					<tr [ngStyle]="{'background-color': bgColor(rowData), 'color': color(rowData) }">
						<td style="padding-left: 2px;">{{rowData.summary}}</td>
						<td style="padding-left: 2px;">{{rowData.detail}}</td>
						<td style="padding-left: 2px;">{{rowData.dateTime}}</td>
						<td class="imgAlign"><img *ngIf="rowData.img" src="{{rowData.img}}" width="50" height="50" (click)="downloadImage(rowData)"></td>
					</tr>
				</ng-template>
			</p-table>
		</ng-template>
	</p-overlayPanel>
	<p-dialog *ngIf="showMapModal" [(visible)]="showMapModal" [modal]="true" [contentStyle]="{'overflow':'visible'}" [draggable]="false" [style]="{width: '900px', height:'520px'}" [baseZIndex]="10000" [dismissableMask]="true">
		<ng-template pTemplate="header">

					<label style="font-weight: bold;font-size: 15.7px;">File Header Translations</label>
					<div style="margin-left: 42%;">
						<p-button label="Add" (onClick)="onAddNewRow()" data-toggle="tooltip" data-placement="top" title="Add Row" [style]="{'margin-right':'5px'}"></p-button>
						<p-button label="Remove" (onClick)="onRemoveRow()" data-toggle="tooltip" data-placement="top" title="Delete" [style]="{'margin-right':'5px'}" [disabled]="selectedMapHeaders.length === 0"></p-button>
						<p-button label="Save" (onClick)="SubmitHomePageMappingModel()" data-toggle="tooltip" data-placement="top" title="Save Changes" [style]="{'margin-right':'5px'}" [disabled]="!allowSave"></p-button>

					</div>
		</ng-template>
		<span style="color: grey;">
			<i class="pi pi-info-circle"></i>
			Please enter comma seperated values to map headers during File Import
		</span>
		<div class="row" style="margin-top: 3px;">
			<div class="col-sm-12">
			  <p-table #translation [value]="translationForGrid" [scrollable]="true" dataKey="headerName" [(selection)]="selectedMapHeaders" scrollHeight="365px">
				<ng-template pTemplate="header" let-columns>
				  <tr >
					<th [style]="{ 'width' : '2%','text-align': 'center'}"><p-tableHeaderCheckbox /></th>
					<th [style]="{ 'width' : '6%','text-align': 'center'}">Header</th>
					<th [style]="{'width': '38%','text-align': 'center'}">Translations</th>
				  </tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowData>
				  <tr style="text-align:left">
					<td [style]="{ 'width' : '2%', 'text-align': 'center','vertical-align': 'top'}">
						<p-tableCheckbox [value]="rowData" />
					</td>
					  <td style="max-width: 12%; width : 8%;overflow-wrap: anywhere;text-align: center;vertical-align: top;" pEditableColumn>
						<!-- {{rowData.headerName}} -->
						<p-cellEditor>
						<ng-template pTemplate="input">
							<input type="text" pInputText spellcheck="false"  [(ngModel)]="rowData.headerName" style="width: 100%;"/>
						</ng-template>
						<ng-template pTemplate="output">
							{{rowData.headerName}}
						</ng-template>
					</p-cellEditor>
					  </td>
					 
					  <td style="width: 38%; overflow-wrap: anywhere;" pEditableColumn>
						  <p-cellEditor>
							  <ng-template pTemplate="input">
								  <textarea spellcheck="false" pInputTextarea [(ngModel)]="rowData.data" rows="5" cols="72" [autoResize]="true" style="width: 100%;"></textarea>
							  </ng-template>
							  <ng-template pTemplate="output">
								  {{rowData.data}}
							  </ng-template>
						  </p-cellEditor>
					  </td>
				  </tr>
				</ng-template>
			  </p-table>
			</div>
		  </div>
	</p-dialog>
</span>

