{
  "name": "precodemanager-ui",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@azure/msal-angular": "^3.0.11",
    "@azure/msal-browser": "^3.7.1",
    "@corteva-research/ngx-components-core": "^3.0.0",
    "@corteva-research/ngx-components-msal-integration": "^3.0.0",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@microsoft/signalr": "^8.0.0",
    "ag-grid-angular": "^31.3.2",
    "ag-grid-community": "~31.3.2",
    "ag-grid-enterprise": "~31.3.2",
    "bootstrap": "^5.3.2",
    "exceljs": "^4.3.0",
    "file-saver": "^2.0.5",
    "font-awesome": "^4.7.0",
    "moment": "^2.29.4",
    "ngx-capture": "^0.14.0",
    "primeflex": "^3.3.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.3",
    "quill": "^1.3.7",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "xlsx-parse-json": "file:plugins/xlsx-parse-json-3.1.1.tgz",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular-eslint/builder": "^17.1.0",
    "@angular-eslint/eslint-plugin": "^17.1.0",
    "@angular-eslint/eslint-plugin-template": "^17.1.0",
    "@angular-eslint/schematics": "^17.1.0",
    "@angular-eslint/template-parser": "^17.1.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "^6.19.0",
    "@typescript-eslint/parser": "^6.19.0",
    "eslint": "^8.56.0",
    "eslint-plugin-unused-imports": "^3.0.0",
    "husky": "^8.0.3",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.4",
    "prettier-eslint": "^16.2.0",
    "tailwindcss": "^3.4.4",
    "typescript": "~5.3.2"
  },
  "overrides": {
    "@corteva-research/ngx-components-core": {
      "@angular/common": "^17.1.0",
      "@angular/core": "^17.1.0"
    },
    "@corteva-research/ngx-components-msal-integration": {
      "@angular/common": "^17.1.0",
      "@angular/core": "^17.1.0"
    }
  }
}
