import { IAfterGuiAttachedParams, IDoesFilterPassParams, IFilterComp, IFilterParams } from 'ag-grid-community';

export class ColorFilter implements IFilterComp {
    filterParams!: IFilterParams;
    filterText!: string | null;
    gui!: HTMLDivElement;
    eFilterlb: any;

    init(params: IFilterParams) {
        this.filterParams = params;
        this.filterText = null;
        this.setupGui(params);
    }

    // not called by AG Grid, just for us to help setup
    setupGui(params: IFilterParams) {
        this.gui = document.createElement('div');
        this.gui.innerHTML = `<div style="margin-left: 5px; margin-bottom: 5px">
                <div style="font-weight: bold; margin-bottom: 3px;">Filter By Color</div>
                <div>
                    <select id="lbColor" name="Colors" size="4" style="width: 7rem;"></select>
                </div>
            </div>
        `;

        const listener = (event: any) => {
            this.filterText = event.target.value;
            params.filterChangedCallback();
        };

        this.eFilterlb = this.gui.querySelector('#lbColor');
        let listbox: any;
        const colName = `${params.column.getColId()}_cellColor`;
        const rowData = (params.rowModel as any).rowsToDisplay.map((d: any) => d.data);
        const dataWithColor = rowData.filter((o: any) => o && o[colName]);
        dataWithColor.map((d: any) => d[colName]).filter((value: string, index: number, array: string[]) => array.indexOf(value) === index).forEach((c: string) => {
            if (c !== '#ffffff')
                listbox +='<option style="background-color: ' + c + ';" value="' + c + '"></option>';
        });
        listbox +='<option value="No Fill">No Fill</option>';
        listbox +='<option value="Clear All">Clear All</option>';
        this.eFilterlb.innerHTML = listbox;
        this.eFilterlb.addEventListener('change', listener);
    }

    getGui() {
        return this.gui;
    }

    doesFilterPass(params: IDoesFilterPassParams) {
        if (this.filterText === 'Clear All')
            return true;
        const node = params;
        const colName = `${this.filterParams.column.getColId()}_cellColor`;
        const value = node.data[colName];
        if (this.filterText === 'No Fill' && (!value || value === '#ffffff'))
            return true;
        if (value && value === this.filterText) {
            return true;
        }
        return false;
    }

    isFilterActive() {
        return this.filterText != null && this.filterText !== '';
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }

        return { value: this.filterText };
    }

    setModel(model: any) {
        const newValue = model == null ? null : model.value;
        this.eFilterlb.value = newValue;
        this.filterText = newValue;
    }

    afterGuiAttached(params?: IAfterGuiAttachedParams): void {
        if (!params?.suppressFocus) {
            // focus the input element for keyboard navigation
            this.eFilterlb.focus();
        }
    }
}