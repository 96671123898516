import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ColDef, GridReadyEvent, SelectionChangedEvent, IMultiFilterParams, FilterChangedEvent, GridPreDestroyedEvent,RowGroupingDisplayType } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { GridPreference, PreCodePreferences } from 'src/app/shared/commonModels';
import { StaticFields } from 'src/app/shared/staticFields';
import { ColorFilter } from 'src/app/shared/colorFilter';
import { SetCustomColorComponent } from 'src/app/shared/setCustomColor';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-manageprogram',
  templateUrl: './manageprogram.component.html',
  styleUrl: './manageprogram.component.scss'
})
export class ManageprogramComponent {
  gridApi: any;
  rowData : any[] = [];
  gridColDef: ColDef[] = [
    { field: 'crop',headerName: 'Crop', enableRowGroup: true,pivot: true, enablePivot: true },
    { field: 'name', headerName: 'Program', enableRowGroup: true,pivot: true, enablePivot: true  }, 
    { field: 'stage',headerName: 'Stage', editable: true, cellEditor: 'agSelectCellEditor', enableRowGroup: true,pivot: true, enablePivot: true ,
      cellEditorParams: (params: any) => {
        const orgRow = this.rowDataCopy.find(r => r.id === params.data.id);
        return {
          values: this.stageDict[orgRow.stage.toUpperCase()]
        };
    } },
    { field: 'year', headerName: 'Year', enableRowGroup: true,pivot: true, enablePivot: true  },
    { field: 'geId', headerName: 'Germplasm Id' },
    { field: 'geName', headerName: 'Germplasm Name' },
    { field: 'cvn',headerName: 'CVN' },
    { field: 'comments', headerName: 'Program Comments', editable: true },
    { field: 'metaData', headerName: 'MetaData', editable: true, cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params: any) => { return { values: this.metaDataList }; }},
    { field: 'customMetaData', headerName: 'Custom MetaData', editable: true, cellEditorParams: {maxLength: 25}},
    { field: 'pedigree', headerName: 'Pedigree' },
    { field: 'parentage', headerName: 'Parentage' },
    { field: 'heteroticGroup', headerName: 'Heterotic Group' }
  ];
  gridOptions = {
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
    columnDefs: this.gridColDef,
    defaultColDef: {
      flex: 1,
      sortable: true,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: ColorFilter
          },
          {
            filter: "agSetColumnFilter"
          },
        ]
      } as IMultiFilterParams,
      resizable: true,
      width: 150,
      minWidth: 120,
      cellStyle: (params: any) => {
        const cellId = `${params.column.getColId()}_cellColor`;
        if(params.data) {
          const cellId = `${params.column.getColId()}_cellColor`;
          if (params.data[cellId])
            return { backgroundColor: params.data[cellId]};
          return null;
        }  else {
          return null;
        }
      }
    },
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
        { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
        {
          statusPanel : SetCustomColorComponent,
          align: 'left',
        },
        { statusPanel: 'agAggregationComponent' }
      ],
    },
    getContextMenuItems: this.getContextMenuItems,
    sideBar: { toolPanels: ['columns', 'filters'] },
    onGridPreDestroyed: this.onGridPreDestroyed
  };
  public groupDisplayType: RowGroupingDisplayType = "multipleColumns";
  selColor: any = '#ffffff';
  stageDict: any = {};
  crops: any[];
  selectedCrop: any;
  metaDataList: any [] = [];
  selectedMetaData: any;
  levels: any[] = [{name: 'Program(s)'},{name: 'Region(s)'},{name: 'EZ(s)'}];
  selectedLevel: any;
  inclusions: any[];
  selectedInclusions: any;
  stages: string[] = [];
  selectedStages: any;
  geLineageChecked: boolean = false;
  seedInventoryChecked: boolean = false;
  popRequestChecked: boolean = false;
  experimentsChecked: boolean = false;
  regions: any[] = [];
  ezs: any = [];
  programs: any = [];
  expandResultPanel: boolean = true;
  expandSearchPanel: boolean = false;
  rowDataCopy: any[] = [];
  userGridPreference : GridPreference = new GridPreference();
  precodePreference : PreCodePreferences = new PreCodePreferences();
  settingPageLoadData: boolean = false;
  foundCell: any[] = [];
  foundIndex = 0;
  lastFoundCell: any;
  found = false;
  showFindReplace = false;
  findText: string;
  replaceText: string;
  matchCaseChecked = false;
  matchWordChecked = false;

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService , public loader: LoadingService) {  }

  get allowReset() : boolean {
    if (this.precodePreference.actionById && this.selectedCrop && this.selectedLevel){
      return this.selectedCrop.name !== this.precodePreference.crop || this.selectedLevel.name !== this.precodePreference.level || 
      (this.selectedInclusions && this.selectedInclusions.length > 0 && JSON.stringify(this.selectedInclusions.sort((a: any,b : any) => a-b)) !== JSON.stringify(this.precodePreference.include.split(",").sort((a: any,b: any) => a-b))) ||
      (this.selectedStages && this.selectedStages.length > 0 && JSON.stringify(this.selectedStages.sort((a: any,b : any) => a-b)) !== JSON.stringify(this.precodePreference.stage.split(",").sort((a: any,b: any) => a-b)))  ||
      this.geLineageChecked !== this.precodePreference.geLineage || this.popRequestChecked !== this.precodePreference.popRequest ||
      this.seedInventoryChecked !== this.precodePreference.seedInventory || this.experimentsChecked !== this.precodePreference.experiments;  
    } else {
      return this.selectedCrop && this.selectedLevel && this.selectedInclusions && this.selectedInclusions.length > 0;
    }
  }

  get updatedData(): any {
    return this.rowData.filter(d => d.hasChanges);
  }

  ngOnInit(): void {  
    this.apiService.getData('GetCrops', new HttpParams()).subscribe((result : any) => {
      if (result.success) {
        this.crops = result.data;  
        if (StaticFields.appUserId)
          this.initialCalls();
        else {
          const interval = setInterval(() => {
            if (StaticFields.appUserId) {
              this.initialCalls();
              clearInterval(interval);
            }
          }, 600);
        }
      } 
    }); 

    window.addEventListener("keydown", function (e) {
      if (e.key === 'f' && e.ctrlKey && (e.target as any).role === 'gridcell') { 
          e.preventDefault();
      }
    });
  }

  initialCalls() {
    if (StaticFields.precodeScreenData && StaticFields.precodeScreenData.crop) {
      this.settingPageLoadData = true;   
      this.setScreenData();       
    } else
      this.getSavedPageStateForUser();
    this.getUserGridPreferences();
  }

  getSavedPageStateForUser() {
    let params = new HttpParams();
    params = params.append('gridName', 'managePrecodeState');
    params = params.append('appUserId', StaticFields.appUserId);
    this.apiService.getData('GetUserGridPreferrences', params, false).subscribe((res: any) => {
      if (res.success && res.data) {
        StaticFields.precodeScreenData = JSON.parse(res.data.preference);
        this.settingPageLoadData = true;
        this.setScreenData();
      } else {
        this.getPreferences();
      }
    });
  }

  getUserGridPreferences() {
    let params = new HttpParams();
    params = params.append('gridName', 'managePrecode');
    params = params.append('appUserId', StaticFields.appUserId);
    this.apiService.getData('GetUserGridPreferrences', params, false).subscribe((res: any) => {
      if (res.success)
        this.userGridPreference = res.data ? res.data : new GridPreference();
    });
  }

  getPreferences() {
    let params = new HttpParams();
    params = params.append('type', 'PreCode');
    params = params.append('actionById', StaticFields.appUserId);
    this.apiService.getData('GetPreferences', params, false).subscribe((res: any) => {
      if (res.success) {
        this.precodePreference = res.data ? res.data : new GridPreference();
      }
      this.setScreenData();
    });
  }
  
  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
    if (this.settingPageLoadData) {
      if (StaticFields.precodeScreenData.selNodes) {
        if(this.gridApi) {
        this.gridApi.forEachNode((node: any) => { 
          const selNodes = StaticFields.precodeScreenData.selNodes;
          if (selNodes.find((id: any) => id === node.data.id || id === node.id))
            node.setSelected(true);
        });
      }
      }
      if (StaticFields.precodeScreenData.colState)
        this.gridApi.applyColumnState({ state: StaticFields.precodeScreenData.colState, applyOrder: true });
      if (StaticFields.precodeScreenData.filters)
        this.gridApi.setFilterModel(StaticFields.precodeScreenData.filters);
      this.settingPageLoadData = false;
    } else if (this.userGridPreference.preference) {
      const savedCols = JSON.parse(this.userGridPreference.preference);
      this.gridApi.applyColumnState({ state: savedCols, applyOrder: true });
    }
  }

  changeCrop() {
    this.selectedInclusions = this.selectedStages = null;
    this.getMasterData();    
    this.getMetaData();
  }

  changeLevel() {
    this.selectedInclusions = null;
    if (this.selectedLevel.name === "Program(s)") {
      this.inclusions = this.programs.map((d: any) => d.code);
    } else if (this.selectedLevel.name === "Region(s)") {
      this.inclusions = this.regions.map((d: any) => d.code);
    } else if (this.selectedLevel.name === "EZ(s)") {
      this.inclusions = this.ezs.map((d: any) => d.code);
    } else if (this.inclusions)
      this.inclusions.splice(0);
    if (this.settingPageLoadData && StaticFields.precodeScreenData && StaticFields.precodeScreenData.include) {
      this.selectedInclusions = StaticFields.precodeScreenData.include;
      this.selectedStages = StaticFields.precodeScreenData.stage;
      this.rowData = StaticFields.precodeScreenData.data;
      this.selColor = StaticFields.precodeScreenData.selectedColor;
      this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
      if (this.rowData.length > 0) {
        this.expandResultPanel = false;
        this.expandSearchPanel = true; 
      }
    } else if (this.precodePreference.actionById && this.inclusions.length > 0) {
      const inclPredicate = this.precodePreference.include.split(",").some(ele => this.inclusions.includes(ele));
      const stagePredicate = this.precodePreference.stage.split(",").some(ele => this.stages.includes(ele));
      this.selectedInclusions =  inclPredicate && this.precodePreference.include!= "" &&  this.precodePreference.include.split(",").length > 0 ?  this.precodePreference.include.split(",") : [] ;
      this.selectedStages = stagePredicate &&  this.precodePreference.stage!= ""  && this.precodePreference.stage.split(",").length > 0 ? this.precodePreference.stage.split(",") : [] ;
    }   
  }

  getRegionsPrograms() {
    let params = new HttpParams();
    params = params.append('cropId', this.selectedCrop.id);
    this.apiService.getData('GetRegions', params, false).subscribe((result : any) => {
      if (result.success) {
        this.regions = result.data;
        this.loader.clearMessage();
        if (this.selectedLevel && this.selectedLevel.name === "Region(s)")
          this.changeLevel();
      }
    });
    this.apiService.getData('GetEvaluationZones', params, false).subscribe((result : any) => {
      if (result.success) {
        this.ezs = result.data;
        this.loader.clearMessage();
        if (this.selectedLevel && this.selectedLevel.name === "EZ(s)")
          this.changeLevel();
      }
    });
    this.apiService.getData('GetPrograms', params, false).subscribe((result : any) => {
      if (result.success) {
        this.programs = result.data;
        this.loader.clearMessage();
        if (this.selectedLevel && this.selectedLevel.name === "Program(s)")
          this.changeLevel();
       }
    });
  }

  getMasterData() {   
    this.stageDict = {};
    this.stages.splice(0);
    let params = new HttpParams();
    params = params.append('type', 'stage');
    params = params.append('crop', this.selectedCrop.name);
    params = params.append('activeOnly', true);
    this.apiService.getData('GetMasterData', params).subscribe((result : any) => {
      if (result.success) {
        result.data.forEach((s: any) => {
          if (!(s.stage in this.stageDict)) {
            this.stageDict[s.stage.toUpperCase()] = [];
            this.stageDict[s.stage.toUpperCase()].push(s.stage);
            this.stages.push(s.stage);
          }
          if (s.nextStage) {
            this.stageDict[s.stage.toUpperCase()].push(s.nextStage);
            const foundNextStage = result.data.find((d: any) => d.stage.toUpperCase() === s.nextStage.toUpperCase())
            if (foundNextStage && foundNextStage.nextStage)
              this.stageDict[s.stage.toUpperCase()].push(foundNextStage.nextStage);
          }
        });       
      } 
      this.getRegionsPrograms();
    });
  }

  getMetaData() {   
    let params = new HttpParams();
    params = params.append('crop', this.selectedCrop.name);  
    params = params.append('activeOnly', true);
    this.apiService.getData('GetMetaData', params, false).subscribe((result : any) => {
      if (result.success) {
       this.metaDataList = [...new Set(result.data.map((d: any) => d.name)),];
      } 
    });
  }

  SearchData() {
    this.rowData.splice(0);
    this.rowDataCopy.splice(0);
    const expandBy = this.getExpandOptions();
    let params = new HttpParams();
    params = params.append('crop', this.selectedCrop.name);
    params = params.append('level', this.selectedLevel.name);
    params = params.append('include', this.selectedInclusions.join());
    params = params.append('stage', this.selectedStages ? this.selectedStages.join() : '');
    params = params.append('expandBy', expandBy);
    this.apiService.getData('SearchPreCodes', params).subscribe((result : any) => {
      if (result.success) {
        if (result.data && result.data.length > 0) { 
          this.expandResultPanel = false;
          this.expandSearchPanel = true;
          this.rowDataCopy = JSON.parse(JSON.stringify(result.data));
          const precodeData: any = new Object();
          precodeData.data = this.rowData = result.data;
          precodeData.crop = this.selectedCrop;
          precodeData.level = this.selectedLevel;
          precodeData.include = this.selectedInclusions;
          precodeData.stage = this.selectedStages;
          precodeData.expandBy = expandBy;
          StaticFields.precodeScreenData = precodeData;
        } else
          this.customMessageService.showMessage({'severity': 'warn', summary: 'No Data', detail: result.message });
      } 
    });
  }

  getExpandOptions() {
    const expandBy: string[] = [];
    if (this.geLineageChecked)
      expandBy.push('GE');
    if (this.seedInventoryChecked)
      expandBy.push('SI');
    if (this.popRequestChecked)
      expandBy.push('POP');
    if (this.experimentsChecked)
      expandBy.push('EXPT');
    return expandBy.join();
  }

  setExpandOptions(expandBy: string[]) {
    expandBy.forEach(item => {
      if (item === 'GE')
        this.geLineageChecked = true;
      else if (item === 'SI')
        this.seedInventoryChecked = true;
      else if (item === 'POP')
        this.popRequestChecked = true;
      else if (item === 'EXPT')
        this.experimentsChecked = true;
    });
  }

  saveGridPreferences() {
    const cols = JSON.stringify(this.gridApi.getColumnState());
    if (!this.userGridPreference.appUserId) {
      this.userGridPreference.appUserId = StaticFields.appUserId;
      this.userGridPreference.gridName= "managePrecode";
    }
    this.userGridPreference.preference = cols;
    this.apiService.putData('SubmitUserGridPreference', this.userGridPreference).subscribe();   
  }

  savePageState() {
    StaticFields.precodeScreenData.expandBy = this.getExpandOptions();
    StaticFields.precodeScreenData.colState = this.gridApi.getColumnState();
    let pageState: GridPreference = new GridPreference();
    pageState.appUserId = StaticFields.appUserId;
    pageState.gridName= "managePrecodeState";
    pageState.preference = JSON.stringify(StaticFields.precodeScreenData);
    this.apiService.putData('SubmitUserGridPreference', pageState).subscribe();
  }

  resetSearch() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to reset the screen?`,
      accept: () => {
        // if (StaticFields.precodeScreenData && StaticFields.precodeScreenData.crop) {
        //   this.setScreenData(false);
        // } else {
        StaticFields.precodeScreenData = null;
        if(this.precodePreference.actionById) {
          this.setScreenData();
        } else {
        this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = null;
        this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = false;
        }
        this.rowData.splice(0);
        this.rowDataCopy.splice(0);
        this.expandResultPanel = true;
        this.expandSearchPanel = false;
        //}
      }
    });
  }

  updateColor() {
    this.selColor =StaticFields.precodeScreenData['selectedColor'];
  }

  setScreenData() {
    if (this.settingPageLoadData) {
      this.selectedCrop = StaticFields.precodeScreenData.crop;
      this.selectedLevel = StaticFields.precodeScreenData.level;
      this.getMasterData();    
      this.getMetaData(); 
      const expandBy = StaticFields.precodeScreenData.expandBy.split(',');
      this.setExpandOptions(expandBy);
    } else if(this.precodePreference.actionById) {
      this.selectedCrop = this.crops.find(ele => ele.name == this.precodePreference.crop);
      this.selectedLevel = this.levels.find(ele => ele.name == this.precodePreference.level);
      this.getMasterData();   
      this.getMetaData(); 
      this.geLineageChecked = this.precodePreference.geLineage;
      this.popRequestChecked = this.precodePreference.popRequest;
      this.seedInventoryChecked = this.precodePreference.seedInventory;
      this.experimentsChecked = this.precodePreference.experiments;
    }
  }

  cancelGridChanges() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: `Are you sure you want to cancel the changes made ?`,     
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(this.rowDataCopy));
      }
    });
  }

  clearFilters() {
    this.gridApi.setFilterModel(null);
  }

  saveChanges() {
    const dataToSubmit = this.updatedData;
    this.apiService.putData("SubmitPreCodeData", dataToSubmit).subscribe();
  }

  onCellValueChanged(item: any) {
    if (item.data.hasChanges) {
      const existingVal = this.rowDataCopy.find(d => d.id === item.data.id);
      item.data.hasChanges = existingVal[item.column.colId] !== item.newValue;
    } else
      item.data.hasChanges = true;
  }

  onSelectionChanged($event: SelectionChangedEvent<any,any>) {
    StaticFields.precodeScreenData.selNodes = [];
    this.gridApi.getSelectedNodes().forEach((n: any) => {
      if (n?.data)
        StaticFields.precodeScreenData.selNodes.push(n.data.id);
    });
  }

  onFilerChanged($event: FilterChangedEvent<any,any>) {
    StaticFields.precodeScreenData.filters = this.gridApi.getFilterModel();
  }

  onGridPreDestroyed($event: GridPreDestroyedEvent<any,any>) {
    StaticFields.precodeScreenData.colState = $event.api.getColumnState();
  }

  getContextMenuItems(params: any) {
    var result = [
      {
        name: 'Fill Up',
        disabled: !(params.value && !isNaN(params.node.id) && Number(params.node.id) > Number(params.node.parent.childrenAfterGroup[0].id) && (params.column.colId === 'comments' || params.column.colId === 'stage')),
        action: function () { params.context.fill(params, "up"); },
      },
      {
        name: 'Fill Down',
        disabled: !(params.value && !isNaN(params.node.id) && Number(params.node.id) < Number(params.node.parent.childrenAfterGroup[params.node.parent.childrenAfterGroup.length - 1].id) && (params.column.colId === 'comments' || params.column.colId === 'stage')),
        action: function () { params.context.fill(params, "down"); }
      },
      {
        name: 'Fill Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 1 && (params.column.colId === 'comments' || params.column.colId === 'stage')),
        action: function () { params.context.fill(params, "selected"); }
      },
      'copy',
      'copyWithHeaders',
      'separator',
      {
        name: 'Set Row Color',
        action: function () { params.context.setColor(params, 'row'); }
      },
      {
        name: 'Set Column Color',
        action: function () { params.context.setColor(params, 'col'); }
      },
      {
        name: 'Set Cell Color',
        action: function () { params.context.setColor(params, 'cell'); }
      },
      'export'
    ]
    return result;
  }

  setColor(params: any, type: string) {
    const selRows : any[] = [];
    const selCells = params.api.getCellRanges();
    if (type === 'col') {
      const cols = selCells.map((c:any) => c.columns).flatMap((cols: any) => cols).map((col: any) => col.colId);
      var uniqueCols = cols.filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
      params.api.forEachNodeAfterFilterAndSort((rowNode: any, index: number) => {
        selRows.push(rowNode);
        uniqueCols.forEach((colId: string) => {
          rowNode.data[`${colId}_cellColor`] = this.selColor;
        });
      });
    } else {
      selCells.forEach((r : any) => {
        const startIdx = r.startRow.rowIndex;
        let endIdx = r.endRow.rowIndex;
        let idx = startIdx;
        if (endIdx < startIdx) {
          idx = endIdx;
          endIdx = startIdx;
        }
        while (idx <= endIdx) {
          var row = params.api.getDisplayedRowAtIndex(idx);
          if (type === 'row') {
            Object.getOwnPropertyNames(row.data).forEach((col) => {
              row.data[`${col}_cellColor`] = this.selColor;
            });
          } else if (type === 'col') {

          } else {
            r.columns.forEach((c : any) => {
              row.data[`${c.colId}_cellColor`] = this.selColor;
            });
          }
          selRows.push(row);
          idx++
        }
      });
    }
    params.api.redrawRows({ rowNodes: selRows });
  }

  fill(params: any, fillType: string) {
    if (fillType === "down" || fillType === "up") {
      const children = params.node.parent.childrenAfterGroup;
      let idx = children.findIndex((c: any) => c.id === params.node.id);
      children.forEach((rowNode: any) => {
        if (fillType === "up" ? idx > rowNode.childIndex : idx < rowNode.childIndex) {
          if (rowNode.data && ((params.column.colId === 'stage' && this.stageDict[this.rowDataCopy.find(r => r.id === rowNode.data.id).stage.toUpperCase()].includes(params.value)) || params.column.colId === 'comments')) {
            rowNode.data[params.column.colId] = params.value;
          }
        }
      });
    } else if (fillType === "selected") {
      params.api.getSelectedRows().forEach((rowNode: any) => {
        if (rowNode && ((params.column.colId === 'stage' && this.stageDict[this.rowDataCopy.find(r => r.id === rowNode.id).stage.toUpperCase()].includes(params.value)) || params.column.colId === 'comments')) {
          rowNode[params.column.colId] = params.value;
        }
      });
    }
    params.api.refreshCells(params);
  }
  
  onCellKeyDown($event: import("ag-grid-community").CellKeyDownEvent<any,any>|import("ag-grid-community").FullWidthCellKeyDownEvent<any,any>) {
    let e = $event.event as unknown as KeyboardEvent;
    if (e.key === 'f' && e.ctrlKey) {
      this.showFindReplace = true;
    }    
  }
  //Find text in ag-grid
  find(clearFoundDataWhenDone: boolean) {
    let found = false;
    let rowNodes: any = [];
    let focusedCell = this.gridApi.getFocusedCell();
    if (focusedCell || this.lastFoundCell) {
      const rowIdx = this.lastFoundCell ? this.lastFoundCell.rowIndex : focusedCell.rowIndex;
      const field = this.lastFoundCell ? this.lastFoundCell.field : focusedCell.column.colId;
      let lastFoundObj: any;
      if (this.foundCell.length > 0) {
        lastFoundObj = this.foundCell[this.foundCell.length - 1];
        if (!(lastFoundObj.rowIndex == rowIdx && lastFoundObj.field == field)) {
          this.foundCell = [];
          this.foundIndex = focusedCell.rowIndex;
        }
      }
    }
    this.gridApi.forEachNode((node: any) => {
      rowNodes.push(node);
    });
    for (let i=this.foundIndex; i < rowNodes.length ; i++) {
      let node = rowNodes[i];
      var rowObj = node.data;
      found = false;
      for (var key in rowObj) {
        if (this.matchWordChecked && rowObj[key] && (this.matchCaseChecked ? rowObj[key].toString() === this.findText : rowObj[key].toString().toUpperCase() === this.findText.toUpperCase()) 
            && !this.foundCell.find(c => c.field === key && c.rowIndex === node.rowIndex)) {
          found = true;
          this.foundCell.push({ 'field': key, 'rowIndex': node.rowIndex});
          break;
        } else if (!this.matchWordChecked && rowObj[key] && (this.matchCaseChecked ? rowObj[key].toString().includes(this.findText) : rowObj[key].toString().toUpperCase().includes(this.findText.toUpperCase())) 
            && !this.foundCell.find(c => c.field === key && c.rowIndex === node.rowIndex)) {
          found = true;
          this.foundCell.push({ 'field': key, 'rowIndex': node.rowIndex});
          break;
        }
      }
      if (found) {
        break;
      }
    }
    if (found) {
      this.lastFoundCell = this.foundCell[this.foundCell.length-1];
      if (clearFoundDataWhenDone) {
        this.gridApi.ensureIndexVisible(this.lastFoundCell.rowIndex, 'middle');
        this.gridApi.ensureColumnVisible(this.lastFoundCell.field);
        this.gridApi.setFocusedCell(this.lastFoundCell.rowIndex, this.lastFoundCell.field);
      }
      this.found = true;
    } else {
      if (this.foundCell.length === 0)
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Found', detail: 'No match found' });
      else if (clearFoundDataWhenDone)
        this.customMessageService.showMessage({'severity': 'info', summary: 'No more matches', detail: 'Reached end of Grid. Click find to search again.' });
      this.foundIndex = 0;
      if (clearFoundDataWhenDone)
        this.foundCell = [];
      this.found = false;
      this.lastFoundCell = null;
    }
  }
  
  //Replace text in ag-grid
  replace(isReplaceAll: boolean) {
    if (this.found || isReplaceAll) {
      let focusedCell: any;
      var cell: any;
      let rowNodes: any[] = [];
      focusedCell = this.gridApi.getFocusedCell();
      if (focusedCell) {
        cell = { rowIndex: focusedCell.rowIndex, field: focusedCell.column.colId };
      } else {
        cell = this.foundCell[this.foundCell.length - 1];
      }
      this.gridApi.forEachNode((node: any) => {
        rowNodes.push(node);
      });
      var rowNode: any;
      var newCellValue: any;
      if (isReplaceAll) {
        this.foundCell = [];
        this.found = true;
        while (this.found) {
          this.find(false);
        }
        let allfoundCell = this.foundCell;
        let updatedCnt = 0;
        for (var i = 0; i < allfoundCell.length; i++) {
          cell = allfoundCell[i];
          if (this.gridApi.getColumnDefs().find((c: any) => c.field === cell.field).editable) {
            rowNode = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
            const currentCellValue = this.gridApi.getValue(cell.field, rowNode).toString();
            if (this.matchCaseChecked)
              newCellValue = currentCellValue.replace(this.findText, this.replaceText);
            else {
              let pattern = new RegExp(this.findText, 'gi');
              newCellValue = currentCellValue.replace(pattern, this.replaceText);
            }
            if (typeof rowNode.data[cell.field] === 'number' && Number(newCellValue))
              newCellValue = Number(newCellValue);
            rowNode.setDataValue(cell.field, newCellValue);
            updatedCnt++;
          }
        }
        this.foundCell = [];
        this.found = false;
        if (allfoundCell.length > 0)
          this.customMessageService.showMessage({'severity': 'info', summary: 'Replaced', detail: `Replaced ${updatedCnt} matches` });
      } else {
        if (this.gridApi.getColumnDefs().find((c: any) => c.field === cell.field).editable) {
          rowNode = this.gridApi.getDisplayedRowAtIndex(cell.rowIndex);
          const currentCellValue = this.gridApi.getValue(cell.field, rowNode).toString();
          if (this.matchCaseChecked)
            newCellValue = currentCellValue.replace(this.findText, this.replaceText);
          else {
            let pattern = new RegExp(this.findText, 'gi');
            newCellValue = currentCellValue.replace(pattern, this.replaceText);
          }

          if (newCellValue != rowNode.data[cell.field].toString()) {
            if (typeof rowNode.data[cell.field] === 'number' && Number(newCellValue))
              newCellValue = Number(newCellValue);
            rowNode.setDataValue(cell.field, newCellValue);
          }
        }
        this.find(true);
      }
    }
  }
}

