import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { SettingService } from '../services/setting.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Settings } from '../models/settings';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private msalService: MsalService, @Inject(SettingService) private settings: Settings) { }
  intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler): Observable<HttpEvent<any>> {
    const endpoint = this.settings.clientScope;                   
    let authRequest = {
      scopes: [endpoint]
    }
    const promise = this.msalService.acquireTokenSilent(authRequest);
    const observable = from(promise);
    return observable.pipe(
      switchMap(token => {
        if (!httpRequest.url.includes('graph')) {
          if (httpRequest.url.includes('ImportFileData')) {
            httpRequest = httpRequest.clone({
              headers: new HttpHeaders({
               'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
               'Pragma': 'no-cache',
               'Expires': '0',
               'Access-Control-Allow-Origin': '*',
               'Access-Control-Allow-Methods': '*',          
               'Authorization': `Bearer ${token['accessToken']}`
             })
           });
          } else {
            httpRequest = httpRequest.clone({
               headers: new HttpHeaders({
                'Cache-Control':  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
                'Pragma': 'no-cache',
                'Expires': '0',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': '*',             
                'Authorization': `Bearer ${token['accessToken']}`
              })
            });
          }
        }
        return httpHandler.handle(httpRequest);
      })
    );
  }
}
