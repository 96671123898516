import { Injectable } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { Observable, Subject } from 'rxjs';
import { CommonService } from "./common.service";

@Injectable({
  providedIn: 'root',
})
export class CustomMessageService {
  private errorMsgs = new Subject<any>();
  errorMsgs$ = this.errorMsgs.asObservable();
  
  constructor(private messageService : MessageService, private commonService: CommonService) {}

  showMessage(msgObj : Message) {
    this.messageService.add(msgObj);
    this.addErrorMessage(msgObj)
  }

  addErrorMessage(error: any) {
    error['dateTime'] =  this.commonService.getDateString(new Date());
    this.errorMsgs.next(error);
  }
}