import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';
import { SettingService } from './setting.service';
import { Settings } from '../models/settings';
import { LoadingService } from './loading.service';
import { AgGridAngular } from 'ag-grid-angular';
import { CustomMessageService } from './custom.message.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // private errorMsgs = new Subject<any>();
  // errorMsgs$ = this.errorMsgs.asObservable();
  constructor(public http: HttpClient, public loader: LoadingService, public customMessageService: CustomMessageService, @Inject(SettingService) public settings: Settings) {}
  getUserRole(groupId: string, member ?: boolean): Observable<any> {
    this.loader.setMessage('Loading...');
    const graphApiUrl = member ? `${this.settings.graphApi}?$filter=id eq '${groupId}'` : "https://graph.microsoft.com/v1.0/me";
    return this.http.get(graphApiUrl);
  }

  public getUserInfo(): any {
    this.loader.setMessage('Loading...');
    let userInfourl = "https://graph.microsoft.com/v1.0/me?$select=onPremisesSamAccountName,displayName,mail";
    return this.http.get(userInfourl);
  }

  getData(methodName: string, httpParams: HttpParams = new HttpParams(), showLoading: boolean = true): any {
    if (showLoading) { this.loader.setMessage('Loading...'); }
     const webApiUrl = `${this.settings.webAppUrl}/${methodName}`;
     return this.http.get(webApiUrl, { params: httpParams}).pipe(   
      map((response: any) => {      
        if (!response.success) {
           this.customMessageService.showMessage({'severity': 'error', summary: 'Fetch Error', detail: response.message });          
        }       
        return response;
      }), 
        finalize(() => {
          if (showLoading) { this.loader.clearMessage(); }
        })
     );
   }
 
   postData(methodName: string, data: any , showLoading: boolean = true, showMessage: boolean = true) :  Observable<any>{
     if(showLoading) { this.loader.setMessage('Loading...'); }
     const webApiUrl = `${this.settings.webAppUrl}/${methodName}`;
     return this.http.post(webApiUrl, data).pipe(     
       finalize(() => {
        if (showLoading)
          this.loader.clearMessage();
       })
     );
   }

   putData(methodName: string, data: any , showLoading: boolean = true, showMessage: boolean = true) {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const webApiUrl = `${this.settings.webAppUrl}/${methodName}`;
    return this.http.put(webApiUrl, data).pipe(
      map((response: any) => {
        if(showMessage) {
          if (response.success) {
            this.customMessageService.showMessage({'severity': 'success', summary: 'Success', detail: response.message });          
          }
          else {
            this.customMessageService.showMessage({'severity': 'error', summary: 'Failed', detail: response.message });
          }
        }
        return response;
      }),
      finalize(() => {
        this.loader.clearMessage();
      })
    );
  }

   put(methodName: string, httpParams: HttpParams , showLoading: boolean = true) {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const webApiUrl = `${this.settings.webAppUrl}/${methodName}`;
    return this.http.put(webApiUrl, {}, {params : httpParams}).pipe(
        finalize(() => {
        this.loader.clearMessage();
      })
    );
  }

   async getFilteredAddresses(filteredText: string, showLoading: boolean = true): Promise<any> {
    if(showLoading) { this.loader.setMessage('Loading...'); }
    const response = await fetch(`https://api.geoapify.com/v1/geocode/autocomplete?text=${filteredText}&format=json&apiKey=6b0ea8fefcaa4e6b9f46772356d5fa80`);
    this.loader.clearMessage();
    return (await response.json()).results;    
   }
   
  
  //  addErrorMessage(error: any) {
  //   error['dateTime'] =  this.getDateString(new Date());
  //   this.errorMsgs.next(error);
  // }
  
  importFileData(url : string,file: any) {
    const formData = new FormData();
    formData.append('file', file);
    const webApiUrl = `${this.settings.webAppUrl}/${url}`;
    return this.http.post(webApiUrl, formData).pipe(
      map(result => result),
      catchError((error: any) => error),
      finalize(() => {      
        //this.loader.clearMessage();   
      })
    );
  }

   public downloadExcelWithMipLabels(agGrid: AgGridAngular) {
    this.loader.setMessage('Loading...');
    let data: any = [];    
    agGrid.api.forEachNodeAfterFilterAndSort(function(node) {      
      if(node.data) 
        data.push(node.data);
    });   

    let columns: any = [];    
    agGrid['columnApi'].getAllDisplayedColumns().map(p => 
    { 
      let col = p.getColDef();    
      columns.push({ "headerName": col.headerName, "field": col.field ? col.field : 'field', "mappedField": col.valueSetter, "objectField": col.valueParser, "objectCondition": col.valueFormatter, "objectConditionalField": col.filterValueGetter, "arrayObjectEnableValue": col.enableValue});      
    });
   
    const agGridData = '{"Columns": ' + JSON.stringify(columns) + ', "Data": ' + JSON.stringify(data) + '}';   
    const webApiUrl = `${this.settings.webAppUrl}/DownloadExcelWithMipLabels`;   
    const httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json'
    });
    return this.http.post(webApiUrl, agGridData, { headers: httpHeaders, responseType: 'arraybuffer' }).pipe(
      finalize(() => {
        this.loader.clearMessage();
      })
    );
  }   
}