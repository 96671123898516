import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { AppConstants, DataType } from 'src/app/shared/appconstants';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  showGrid: boolean = false;
  gridApi: any;
  selType: any;
  rowData: any[] = [];
  rowDataCopy: any[] = [];
  crops: string[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: 'agSetColumnFilter',
    resizable: true,
    editable: true,
    width: 70,
    minWidth: 20
  };
  public columnDefs: ColDef[];
  public columnDefsStageData: ColDef[] = [
    { field: 'crop', headerName: 'Crop', cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => { return { values: this.crops, valueListMaxHeight: 250 } }},
    { field: 'stage',headerName: 'Stage' },
    { field: 'nextStage', headerName: 'Next Stage' },   
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor'}
  ];
  public columnDefsMetaData: ColDef[] = [
    { field: 'name',headerName: 'MetaData', cellEditor: 'agTextCellEditor' },
    { field: 'type', headerName: 'Type', cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => { return { values: AppConstants.MetaDataTypes, valueListMaxHeight: 250 }}},  
    { field: 'crop', headerName: 'Crop', cellEditor: 'agSelectCellEditor',
      cellEditorParams: () => { return { values: this.crops, valueListMaxHeight: 250 } }},  
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor'}
  ];

  get dataTypes(): any [] {
    return AppConstants.DataTypes;
  }
  
  get SelType(): any {
    return this.selType;
  }
  set SelType(type) {
    this.selType = type;
    if(type != undefined) {
    if (type.name == DataType.MetaData) {
       this.columnDefs = this.columnDefsMetaData;     
       this.crops.unshift("All");  
       this.getMetaData();
    } else {
      this.columnDefs = this.columnDefsStageData;
      this.crops.splice(this.crops.indexOf("All"), 1);  
      this.getMasterData();
    }
   }
  }

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.apiService.getData('GetCrops').subscribe((result : any) => {
      if (result.success) {
        this.crops = result.data.map((d: any) => d.name);       
      }      
    });      
  }

  get getSelectedRows(): any {
    return this.gridApi ? this.gridApi.getSelectedRows() : null;
  }

  get updatedData(): any {
    return this.rowData.filter(d => d.hasChanges);
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }

  onCellValueChanged(item: any) {
    if(this.selType.name == DataType.MetaData) {
       if (item.column.colId === 'name' || item.column.colId === 'crop' || item.column.colId === 'type') {
        const foundItems = this.rowData.filter(d => d.name === item.data.name && d.crop === item.data.crop && d.type === item.data.type);
        if (foundItems.length > 1) {
          this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "Duplicate metadata, crop and type combination found." });
          item.data[item.column.colId] = item.newValue = item.oldValue;
          this.gridApi.refreshCells(this.getSelectedRows);
          return;
        }
      }
    }
    else {
      if (item.column.colId === 'stage' || item.column.colId === 'crop') {
        const otherCol = item.column.colId === 'stage' ? 'crop' : 'stage';
        const foundItems = this.rowData.filter(d => d[item.column.colId] === item.newValue && item.data[otherCol] === d[otherCol]);
        if (foundItems.length > 1) {
          this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "Duplicate crop stage combination found." });
          item.data[item.column.colId] = item.newValue = item.oldValue;
          this.gridApi.refreshCells(this.getSelectedRows);
          return;
        }
      }
    }
    if (item.data.id && item.data.hasChanges) {
      const existingVal = this.rowDataCopy.find(d => d.id === item.data.id);
      item.data.hasChanges = existingVal[item.column.colId] !== item.newValue;
    } else
      item.data.hasChanges = true;
  }

  addRow() {
    if (this.selType.name == 'Meta Data') {
      this.addMetaDataRow();
    } else {
      this.addStageRow();
    }  
  }

  addStageRow() {
    if (this.updatedData.filter((d: any) => !d.id && !(d.crop && d.stage)).length > 0) {
      this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "You can't add a new row till all values have been provided for the last one." });
      return;
    }
    const newRow = { type: this.selType.name, crop: '', stage : '', nextStage : '', isActive: true, hasChanges: true }
    this.rowData.unshift(newRow);
    this.gridApi.setGridOption('rowData', this.rowData);
  }

  addMetaDataRow() {
    if (this.updatedData.filter((d: any) => !d.id && !(d.name && d.type && d.crop)).length > 0) {
      this.customMessageService.showMessage({'severity': 'warn', summary: 'Not Allowed', detail: "You can't add a new row till all values have been provided for the last one." });
      return;
    }
    const newRow = { name: '', type : '', crop: '', isActive : true, hasChanges: true }
    this.rowData.unshift(newRow);
    this.gridApi.setGridOption('rowData', this.rowData);
  }

  saveChanges() {
    const validData = this.updatedData.filter((d: any) => this.selType.name == DataType.MetaData ? d.name && d.type && d.crop : d.crop && d.stage);
    if (this.updatedData.length > validData.length) {
      if (validData.length === 0) {
        this.customMessageService.showMessage({'severity': 'warn', summary: 'Invalid Rows', detail: `${this.updatedData.length} row(s) with incomplete data found, can't be updated.` });
        return;
      }
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `${this.updatedData.length - validData.length} row(s) with incomplete data found. Do you want to submit remaining ${validData.length} row(s).`,      
        accept: () => {
          this.submitData(validData);
        }
      });
    } else
      this.submitData(validData);
  }

  submitData(validData: any) {
    this.apiService.putData(this.selType.name == DataType.MetaData ? "SubmitMetaData" : "SubmitMasterData", validData).subscribe((result : any) => {
      if (result.success) {    
        this.rowData = result.data;
        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
       } 
    });
  }

  getMasterData() {
    let params = new HttpParams();
    params = params.append('type', this.selType.name);
    this.apiService.getData('GetMasterData', params).subscribe((result : any) => {
      if (result.success) {
        this.rowData = result.data;
        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
        this.showGrid = true;
      } 
    });
  }

  getMetaData() {
    let params = new HttpParams();
    this.apiService.getData('GetMetaData', params).subscribe((result : any) => {
      if (result.success) {
        this.rowData = result.data;
        this.rowDataCopy = JSON.parse(JSON.stringify(this.rowData));
        this.showGrid = true;
      } 
    });
  }

  reset() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel all the changes?',      
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(this.rowDataCopy));
        this.gridApi.setGridOption('rowData', this.rowData);
      }
    }); 
  }
}