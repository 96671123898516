import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ApiService } from '../../services/api.service';
import { StaticFields } from '../../shared/staticFields';
import { CustomMessageService } from '../../services/custom.message.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  hideEditorCtrl = false;
  homeScreenData = {
    "actionBy" : "",
    "actionById" : "",
    "actionOn" : "",
    "id" : "",
    "box1" : "",
    "box2" : "",
    "box3" : "",
    "box4" : "",
    "mappingModel" : ""
  };
  isAdmin = false;
  isPCMAdmin: boolean = false;
  isPCMUser: boolean = false;
  
  constructor( public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService, private msalService : MsalService) { }
 
  ngOnInit(): void {    
    let allAccounts = this.msalService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      const roles = account.idTokenClaims!.roles;
      this.isPCMAdmin = roles!.find(ele => ele == "PCM.Admin") ? true : false;
      this.isPCMUser = roles!.find(ele => ele == "PCM.User") ? true : false;
    }
    if (StaticFields.appUserId)
      this.homeScreenData = JSON.parse(JSON.stringify(StaticFields.homeScreenData));
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.homeScreenData = JSON.parse(JSON.stringify(StaticFields.homeScreenData));
          clearInterval(interval);
        }       
      }, 600);
    }
  } 
  
  editContent() {
    this.hideEditorCtrl = true;
  }

  cancelEdit() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to cancel the changes made?',
      accept: () => {
        this.hideEditorCtrl = false;
        this.homeScreenData = StaticFields.homeScreenData ? JSON.parse(JSON.stringify(StaticFields.homeScreenData)) : this.homeScreenData;
          //Actual logic to perform a confirmation
      }
    });    
  }

  saveHomePageData() {
    let submitData : any = this.homeScreenData;
    this.apiService.putData('SubmitHomePageData', JSON.stringify(submitData)).subscribe((result : any)  => {
      if (result.success) {
        StaticFields.homeScreenData = result.data;
        this.homeScreenData = JSON.parse(JSON.stringify(StaticFields.homeScreenData));
        this.hideEditorCtrl = false;
      }     
    });
  }
}