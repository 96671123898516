<p-panel [toggleable]="true" [collapsed]="expandSearchPanel" header="Search Criteria" >
    <ng-template pTemplate="icons">
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(selectedCrop && selectedLevel && selectedInclusions)" title="Search Data" (onClick)="SearchData()"> Retrieve</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!allowReset" title="Cancel Changes" (onClick)="resetSearch()"> Reset</p-button>
    </ng-template>
    
    <div class="flex flex-row flex-wrap ml-2 mt-0.5  gap-6" style="padding-top: 15px;">
        <div>
            <label for="crop">Crop*: </label>
            <p-dropdown [options]="crops" [(ngModel)]="selectedCrop" (ngModelChange)="changeCrop()" [filter]="true" optionLabel="name" placeholder="Select" />
        </div>
        <div>
            <label for="level">Level*: </label>
            <p-dropdown [options]="levels" [(ngModel)]="selectedLevel" (ngModelChange)="changeLevel()" optionLabel="name" placeholder="Select" />
        </div>
        <div>
            <label for="include">Include*:</label>
            <p-multiSelect [options]="inclusions" [(ngModel)]="selectedInclusions" placeholder="Select" [resetFilterOnHide]="true" />
        </div>
        <div>
            <label for="stage">Stage:</label>
            <p-multiSelect [options]="stages" [(ngModel)]="selectedStages" placeholder="Select" [resetFilterOnHide]="true" />
        </div>
    </div>
    <div style="margin: 10px;">
        <label style="text-transform: capitalize;font-weight: 800;">Expand By :</label>
    </div>
    <div class="flex flex-row flex-wrap ml-2 pb-4 gap-6">
        <div>            
            <p-checkbox [(ngModel)]="geLineageChecked" label="GE Lineage" [binary]="true" inputId="binary" />
        </div>
        <div>
            <p-checkbox [(ngModel)]="seedInventoryChecked" label="Seed Inventory" [binary]="true" inputId="binary" />
        </div>
        <div><p-checkbox [(ngModel)]="popRequestChecked" label="Pop Request" [binary]="true" inputId="binary" />
        </div>
        <div>
            <p-checkbox [(ngModel)]="experimentsChecked" label="Experiments" [binary]="true" inputId="binary" />
        </div>
    </div>
</p-panel>
<div style="height: 3px;"></div>
<p-panel [toggleable]="true" [collapsed]="expandResultPanel" header="PreCode Information" class="resultsPanel" >
    <ng-template pTemplate="icons">
        <span class="buttonsLayout">
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Save Grid Preferences" (onClick)="saveGridPreferences()"> Save Grid Preferences</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Save data for next session" (onClick)="savePageState()"> Save Session</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="updatedData.length === 0" title="Save" (onClick)="saveChanges()"> Update</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Code"> Code</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Line Graph"> Line Graph</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Apex Job"> Create APEX Job</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Germplasm Assessments"> Add Germplasm Assessments</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(rowData.length > 0)" title="Clear Filters" (onClick)="clearFilters()"> Clear Filters</p-button>
            <p-button  id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="updatedData.length === 0" title="Cancel Changes" (onClick)="cancelGridChanges()"> Cancel</p-button>            
        </span>   
    </ng-template>
    <span>
        <ag-grid-angular *ngIf="rowData.length > 0"#agGrid 
        (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueChanged($event)" (selectionChanged)="onSelectionChanged($event)" (cellKeyDown)="onCellKeyDown($event)"
        style="height: 73vh; width: 100%;font-size: 14px;" class="ag-theme-balham" (filterChanged)="onFilerChanged($event)"
        [rowData]="rowData" [enableRangeSelection]=true [gridOptions]="gridOptions" [groupDisplayType]="groupDisplayType"
        [rowSelection]="'multiple'" [animateRows]="true" [context]="this"></ag-grid-angular>
    </span>
    <p-dialog [(visible)]="showFindReplace" [draggable]="false" header="Find/Replace" [baseZIndex]="10000" [modal]="false" [dismissableMask]="false" appendTo="body" [position]="'topright'" styleClass="headerPanel">
        <div class="grid" style="margin: 10px; margin-top: 2px;">
            <label style="width: 120px; margin-top: 5px;">Find what</label>
            <input type="text" pInputText [(ngModel)]="findText" style="margin-left: 5px; margin-right: 25px;" />
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!findText" title="Find Next" (onClick)="find(true)" [style]="{width: '97px', display: 'block', padding: '7px'}"> Find Next</p-button>
        </div>
        <div class="grid" style="margin: 10px;">
            <label style="width: 120px; margin-top: 5px;">Replace With</label>
            <input type="text" pInputText [(ngModel)]="replaceText" style="margin-left: 5px; margin-right: 25px;"/>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(findText && replaceText)" title="Replace next match" (onClick)="replace(false)" [style]="{width: '97px', display: 'block', padding: '7px'}"> Replace</p-button>
        </div>
        <div class="grid" style="margin: 10px;">
            <div style="margin-top: 5px;">
                <p-checkbox [(ngModel)]="matchCaseChecked" label="Match case" (onChange)="foundCell=[]" [binary]="true" inputId="binary" />
            </div>
            <div style="margin-left: 50px; margin-right: 65px; margin-top: 5px; margin-bottom: 2px;">
                <p-checkbox [(ngModel)]="matchWordChecked" label="Match Word" (onChange)="foundCell=[]" [binary]="true" inputId="binary" />
            </div>
            <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" [disabled]="!(findText && replaceText)" title="Replace All" (onClick)="replace(true)" [style]="{padding: '7px'}"> Replace All</p-button>
        </div>
    </p-dialog>
</p-panel>
<p-confirmDialog [style]="{'width' : '450px', 'height' : '230px'}">  
</p-confirmDialog> 
