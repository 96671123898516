import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService { 
  constructor() {}

  public getDateString(date: Date) {   
    const d = date.getDate();
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    const dateText = (m < 10 ? '0' : '') + m + '/' + (d < 10 ? '0' : '') + d + '/' + y + " ";
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const mins = minutes < 10 ? '0' + minutes : minutes;
    const secs = seconds < 10 ? '0' + seconds : seconds;
    const timeText = hours + ':' + mins + ':' + secs + ' ' + ampm;
    return dateText + timeText;
  }    
}