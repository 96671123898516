export class GridPreference {
    appUser?: object;
    appUserId: any;
    gridName: string;
    preference: string;
}

export class PCMErrorScreenShot {
      errorDateTime : Date =  new Date();
      errorMessage : string;
      screenShot : string;
      actionById :string;
}

export class PreCodePreferences {
    type : string;
    crop : string;
    level : string;
    include : string;
    stage :string;
    geLineage : boolean;
    seedInventory : boolean;
    popRequest : boolean;
    experiments : boolean;
    actionById : string;
}